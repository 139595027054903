<template>
  <!--begin::Product Internal Listing-->
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-2">
      <div class="card-title justify-space-between width-100">
        <h3 class="card-label" style="font-weight: bold">This Week PM</h3>
      </div>
    </div>
    <v-container fluid>
      <ListingTable
        :column-count="columnCount"
        :data-loading="dataLoading"
        :row-data="dataRows"
      >
        <template v-slot:thead>
          <thead>
            <tr>
              <th class="simple-table-th">Preventive #</th>
              <th class="simple-table-th">Customer Info</th>
              <th class="simple-table-th">Site Location</th>
              <th class="simple-table-th">Contract Date</th>
              <th class="simple-table-th">Details</th>
              <th class="simple-table-th">Schedule</th>
              <th class="simple-table-th">Status</th>
              <th class="simple-table-th">Created Time</th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody class="custom-border-bottom custom-border-top">
            <template v-if="dataRows?.length">
              <template v-for="(data, index) in dataRows">
                <v-hover :key="index" v-slot="{ hover }">
                  <tr
                    class="alternate-listing-row"
                    :key="index"
                    v-on:click="detailPm(data.id)"
                  >
                    <td>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <Barcode class="" :barcode="data.unique_id"></Barcode>
                      </p>

                      <p class="m-0 custom-nowrap-ellipsis">
                        <span
                          class="schedule-text"
                          v-on:click.stop.prevent="openSchedule(data.id)"
                          >Show Schedule</span
                        >
                      </p>
                    </td>
                    <td>
                      <div class="warranty-listing-product">
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Display Name: </b>
                          <template
                            v-if="data.customer && data.customer.display_name"
                            >{{ data.customer.display_name }}</template
                          >
                          <template v-else
                            ><em class="text--secondary"
                              >No Display Name</em
                            ></template
                          >
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Company: </b>
                          <template
                            v-if="data.customer && data.customer.company_name"
                            >{{ data.customer.company_name }}</template
                          >
                          <template v-else
                            ><em class="text--secondary"
                              >No Company</em
                            ></template
                          >
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Email: </b>
                          <template
                            v-if="data.customer && data.customer.company_email"
                            >{{ data.customer.company_email }}</template
                          >
                          <template v-else
                            ><em class="text--secondary">No Email</em></template
                          >
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Phone: </b>
                          <template
                            v-if="data.customer && data.customer.company_number"
                            >{{ data.customer.company_number }}</template
                          >
                          <template v-else
                            ><em class="text--secondary">No Phone</em></template
                          >
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="property-listing-customer">
                        <p
                          class="m-0 custom-nowrap-ellipsis-four-line word-break"
                          v-if="data.property"
                        >
                          {{ data.property.street_1 }}
                          {{ data.property.street_2 }}
                          <br />
                          {{ data.property.unit_no }}
                          {{ data.property.country }}
                          <template
                            v-if="
                              data &&
                              data.property &&
                              data.property.zip_code != '000000'
                            "
                          >
                            , {{ data.property.zip_code }}
                          </template>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="warranty-listing-product">
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>Start Date: </b>
                          <template v-if="data && data.start_date">
                            <v-chip
                              small
                              outlined
                              color="red"
                              label
                              class="ml-1 mb-1"
                            >
                              {{ formatDate(data.start_date) }}
                              {{ formatDateStartTime(data.start_date) }}
                            </v-chip>
                          </template>
                          <template v-else
                            ><em class="text--secondary"
                              >No Start Date</em
                            ></template
                          >
                        </p>
                        <p class="m-0 custom-nowrap-ellipsis">
                          <b>End Date: </b>
                          <template v-if="data && data.end_date">
                            <v-chip
                              small
                              outlined
                              color="green"
                              label
                              class="ml-2"
                            >
                              {{ formatDate(data.end_date) }}
                              {{ formatDateStartTime(data.end_date) }}
                            </v-chip>
                          </template>
                          <template v-else
                            ><em class="text--secondary"
                              >No End Date</em
                            ></template
                          >
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="m-0 custom-nowrap-ellipsis d-flex">
                        <div class="font-weight-600">Title :</div>
                        <template v-if="data && data.title">
                          <div class="my-auto">{{ data.title }}</div>
                        </template>
                        <em class="text-muted" v-else> no title </em>
                      </div>
                      <div class="m-0 custom-nowrap-ellipsis d-flex">
                        <div class="font-weight-600">Cost:</div>
                        <template v-if="data && data.cost">
                          <div class="font-weight-700 my-auto">
                            {{ formatMoney(data.cost) }}
                          </div>
                        </template>
                        <template v-else
                          ><em class="text--secondary">No cost</em></template
                        >
                      </div>
                    </td>
                    <td>
                      <div class="contract-listing-customer">
                        <p class="m-1 custom-nowrap-ellipsis-count mb-2">
                          <v-badge
                            color="cyan"
                            :content="data.schedule_count"
                            class="mr-9 ml-2"
                          >
                            <!--   Item Two -->
                          </v-badge>
                          <b>Schedule Count </b>
                          <!-- <template v-if="data.total_service">{{
                                data.total_service
                              }}</template> -->
                        </p>

                        <p class="m-1 custom-nowrap-ellipsis-count">
                          <v-badge
                            :color="data.visit_count > 0 ? 'green' : 'red'"
                            :content="data.visit_count"
                            class="mr-9 ml-2"
                          >
                          </v-badge>
                          <b>Visit Count</b>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="project-listing-status">
                        <CustomStatus
                          small
                          :status="data.status"
                          endpoint="preventive-maintanance/status"
                        ></CustomStatus>
                      </div>
                      <v-chip
                        v-if="data.is_extended && data.is_extended == 1"
                        label
                        small
                        outlined
                        class="font-weight-600 custom-grey-border text-uppercase ml-2"
                        text-color=""
                        color="orange"
                      >
                        Extended
                      </v-chip>
                    </td>
                    <td class="simple-table-td">
                      <TableActivity
                        v-if="!lodash.isEmpty(data.added_by)"
                        :data="data"
                      >
                        <template v-slot:display_name>
                          {{ data.added_by.display_name }}
                        </template>
                        <template v-slot:date_time>
                          {{ data.created_at }}
                        </template>
                        <template v-slot:format_date_time>
                          {{ formatDate(data.added_at) }}
                          {{ formatDateSartTime(data.added_at) }}
                        </template>
                      </TableActivity>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </template>
            <tr v-else>
              <td :colspan="columnCount">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no pm at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </ListingTable>
      <ListingFooter
        :dataLoading="dataLoading"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :totalPages="totalPages"
        removePage
      ></ListingFooter>
      <PMSchedule
        v-if="scheduleDialog"
        :parent-id="pmId"
        :dialogStatus="scheduleDialog"
        :updateted-data="pmVisitArray"
        v-on:close:dialog="scheduleDialog = false"
      >
      </PMSchedule>
    </v-container>
  </div>
  <!--end::Product Internal Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import moment from "moment";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import PMSchedule from "@/view/pages/preventive-maintanance/ManagePMSchedule.vue";

export default {
  mixins: [CommonMixin, FileManagerMixin, ValidationMixin],
  name: "equipment-internal-list",
  data() {
    return {
      pmId: 0,
      scheduleDialog: false,
      pmVisitArray: [],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 15,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      timeoutLimit: 500,
    };
  },
  props: {},
  watch: {},
  components: {
    CustomStatus,
    TableActivity,
    PMSchedule,
    /*   Dialog, */

    ListingFooter,
    ListingTable,
    Barcode,
  },
  methods: {
    openSchedule(id) {
      this.pmId = id;
      this.scheduleDialog = true;
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    detailPm(param) {
      this.$router.push(
        this.getDefaultRoute("preventive-maintanance.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    formatDateSartTime(row) {
      return moment(row).format("hh:mm A");
    },
    get_all_pm() {
      const _this = this;
      let filter = {
        status: "all",
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        entity: 0,
        currentWeek: "this_week",
        /*     search: _this.search || undefined,     */
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: `preventive-maintanance`,
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    const _this = this;
    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.get_all_pm();
    });
    _this.get_all_pm();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    columnCount() {
      let result = 9;
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows?.length : 0);
    },
  },
};
</script>
